import axios from 'axios';
import connection from './connection';
import {
  GET_CONFIGURAION_URL,
  POST_CONFIGURAION_URL,
  GET_DATATABLE_URL,
  GET_TRANSLATIONS_URL,
  GET_CATALOG_ITEM_URL,
} from './constants';

const proxyQuery = '&cacheScope=123&cacheMaxAge=31536000';
const applyProxyQuery = (useProxy) => (useProxy ? proxyQuery : '');

/**
 * Get Asset Data
 */
export const getCatalogItem = (assetId) =>
  new Promise(async (resolve) => {
    if (!assetId) resolve(undefined);
    const { authToken, threekitEnv, useProxy, proxyDomainChina } =
      connection.getConnection();
    const baseUrl = useProxy ? proxyDomainChina : threekitEnv;
    const url = `${baseUrl}${GET_CATALOG_ITEM_URL}/${assetId}?bearer_token=${authToken}&publicShare=true${applyProxyQuery(
      useProxy
    )}`;
    console.log(url);
    const response = await fetch(url);
    const data = await response.json();
    resolve(data);
  });

/**
 * Function to get a datatable by Datatable ID
 */
export const getAttributeGrouping = (datatableId) =>
  new Promise(async (resolve) => {
    if (!datatableId) resolve(undefined);
    const { orgId, authToken, threekitEnv, useProxy, proxyDomainChina } =
      connection.getConnection();
    const baseUrl = useProxy ? proxyDomainChina : threekitEnv;
    const url = `${baseUrl}${GET_DATATABLE_URL}/${datatableId}/download?bearer_token=${authToken}&orgId=${orgId}${applyProxyQuery(
      useProxy
    )}`;
    const response = await fetch(url);
    const data = await response.text();

    const csvData = data
      .replace(/"/g, '')
      .split('\n')
      .map((el) => el.split(','))
      .reduce((output, row, rowIdx) => {
        if (!rowIdx) return output;
        const groupId = row.shift();
        return Object.assign(output, {
          [groupId]: row.filter((el) => !!el.length),
        });
      }, {});
    resolve(csvData);
  });

/**
 * Function to get all Configurations saved on
 * the Threekit Platform
 */
export const getSavedConfigurations = () =>
  new Promise(async (resolve) => {
    const { orgId, authToken, threekitEnv, useProxy, proxyDomainChina } =
      connection.getConnection();
    const baseUrl = useProxy ? proxyDomainChina : threekitEnv;
    const url = `${baseUrl}${GET_CONFIGURAION_URL}?orgId=${orgId}&bearerToken=${authToken}${applyProxyQuery(
      useProxy
    )}`;
    const response = await fetch(url);
    const data = await response.json();
    resolve(data);
  });

/**
 * Function to get a saved configuration by its id
 * or shortId
 */
export const getSavedConfiguration = (configurationId) =>
  new Promise(async (resolve, reject) => {
    const { orgId, authToken, threekitEnv, useProxy, proxyDomainChina } =
      connection.getConnection();
    const baseUrl = useProxy ? proxyDomainChina : threekitEnv;
    if (!configurationId) reject('Missing configuration id');
    if (!orgId) reject('Missing orgId');
    if (!authToken) reject('Missing public auth token');
    const url = `${baseUrl}${GET_CONFIGURAION_URL}/${configurationId}?orgId=${orgId}&bearer_token=${authToken}${applyProxyQuery(
      useProxy
    )}`;
    const response = await fetch(url);
    const data = await response.json();
    resolve(data);
  });

/**
 * Function to save a new configuration, including a
 * thumbnail and any metadata associated with the
 * configuration
 *
 * The function requires the following values as part
 * of the configuration object:
 * - orgId
 * - authToken
 */
export const postConfiguration = (config) => {
  const { orgId, authToken, threekitEnv } = connection.getConnection();
  const {
    assetId,
    customerId,
    configuration,
    metadata,
    productVersion,
    attachments,
  } = Object.assign(
    {
      assetId: window.threekit.player.assetId,
      configuration: window.threekit.configurator.getConfiguration(),
      metadata: undefined,
      productVersion: 'v1',
      attachments: undefined,
    },
    config
  );

  const url = `${threekitEnv}${POST_CONFIGURAION_URL}?orgId=${orgId}&bearer_token=${authToken}`;

  const fd = new FormData();
  fd.append('productId', assetId);
  fd.append('variant', JSON.stringify(configuration));
  fd.append('productVersion', productVersion);
  if (metadata && Object.keys(metadata))
    fd.append('metadata', JSON.stringify(metadata));
  if (customerId) fd.append('customerId', customerId);
  if (attachments && Object.keys(attachments).length) {
    let attachmentsPrepped = {};
    Object.entries(attachments).forEach(([key, file]) => {
      fd.append('files', file);
      attachmentsPrepped = Object.assign({}, attachmentsPrepped, {
        [key]: file.name,
      });
    });
    fd.append('attachments', JSON.stringify(attachmentsPrepped));
  }

  return new Promise(async (resolve) => {
    const response = await axios.post(url, fd);
    resolve(response.data);
  });
};

export const getTranslations = () =>
  new Promise(async (resolve) => {
    const { authToken, threekitEnv, useProxy, proxyDomainChina } =
      connection.getConnection();
    const baseUrl = useProxy ? proxyDomainChina : threekitEnv;

    const url = `${baseUrl}${GET_TRANSLATIONS_URL}?bearer_token=${authToken}${applyProxyQuery(
      useProxy
    )}`;
    const response = await fetch(url);
    const data = await response.text();

    const csvData = data
      .replace(/"/g, '')
      .split('\n')
      .map((el) => el.split(','));
    const languages = csvData[0];
    const translationMap = csvData.reduce((output, row, idx) => {
      if (!idx) return output;
      output[row[0]] = row.reduce((result, el, i) => {
        if (!i) return result;
        return Object.assign(result, {
          [languages[i]]: el.length ? el : undefined,
        });
      }, {});

      return output;
    }, {});
    resolve(translationMap);
  });

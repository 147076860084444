class ThreekitConnection {
  constructor() {
    this._authToken = undefined;
    this._orgId = undefined;
    this._assetId = undefined;
    //this._threekitEnv = 'https://preview.threekit.com';
    this._useProxy = false;
  }

  async connect(config) {

    const preppedConfig = {
      ...config,
      authToken: this._authToken || config.authToken,
      orgId: this._orgId || config.orgId,
    };

    this._authToken = preppedConfig.authToken;
    this._orgId = preppedConfig.orgId;
    this._assetId = preppedConfig.assetId;

    if (preppedConfig.threekitEnv)
      this._threekitEnv = `https://${preppedConfig.threekitEnv}`;

    if (preppedConfig.useProxy) this._useProxy = true;
  }

  getConnection() {
    if (!this._authToken)
      throw new Error('Connection has not been established');
    return {
      authToken: this._authToken,
      orgId: this._orgId,
      assetId: this._assetId,
      threekitEnv: this._threekitEnv,
      proxyDomainChina: this._proxyDomainChina,
      useProxy: this._useProxy,
    };
  }
}

const connection = new ThreekitConnection();

export default connection;
